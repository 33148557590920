import fetchBasket from '../core/basket/fetchBasket';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { message } from 'antd';
import updateBasketAction from '../core/basket/updateBasket';
import deleteBasket from '../core/basket/deleteBasket';
import insertBasket from '../core/basket/insertBasket';
import getStockAndPrice from '../core/basket/getStockAndPrice';
import fetchPriceAndRoute from '../core/price/fetchPriceAndRoute';
import fetchPriceAndRoutev2 from '@/core/price/fetchPriceAndRoutev2';
const initialState = {
  visible: false,
  basketList: [],
  reload: 0,
  loading: 0,
  loadingPrice: false,
  priceList: {},
  totalPrices: {
    NetPrice: 0,
    GrossPrice: 0,
    Vat: 0,
    IncVAT: 0
  },
  deliveries: [], //kendi çıkışları
  loadingAllPriceList: false, //Main layout ilk mount edildiği zaman load işlemi için yapıldı
  loadingAllBasketList: false //Main layout ilk mount edildiği zaman load işlemi için yapıldı
}



const getBasketList = (callback) => async dispatch => {
  return (new Promise(async (resolve, reject) => {
    const actions = basket.actions;
    dispatch(actions.setLoading(true))
    await fetchBasket().then(e => {
      dispatch(actions.setBasketList(e.data))

      dispatch(actions.setDeliveries(e.deliveries))

      resolve(true)
      if (callback && typeof callback == 'function') {
        callback(null, e.data)
      }
    }).catch(f => {
      message.error(f)
      if (callback && typeof callback == 'function') {
        callback(true, f.message)
      }
    }).finally(() => dispatch(actions.setLoading(false)))
  }))
}

// const getBasketStockAndPrice = (basketId, callback) => async dispatch => {
//   const actions = basket.actions;
//   getStockAndPrice(basketId).then(e => {
//     callback(null, e)
//   }).catch(f => {
//     callback(f)
//   })
// }







export const deleteBasketById = createAsyncThunk('basket/deleteBasketById',
  async (data, { dispatch, getState }) => {

    let { basketId, cb } = data;
    try {
      const basketList = getState().basket.basketList;


      await deleteBasket(basketId)

      if (cb) cb(null)
      return basketId
    } catch (f) {
      if (cb) cb(true, null)
      return new Error(f?.response?.data?.message || "System error. Please try again.")
    }
  }


)




//Silinecek

//Store update eder.
export const updateStoreBasket = createAsyncThunk('/basket/updateStoreBasket',
  async (data, { dispatch, getState }) => {
    let { Quantity, basketId, cb, } = data;
    let basketList = getState().basket.basketList;
    try {
      await updateBasketAction(Quantity, basketId)
      if (cb) cb(null)
      return basketId
    } catch (e) {
      cb(true)
      return new Error(e?.response?.data?.message || "System error !")
    }
  }
)



export const insertNewBasket = createAsyncThunk('basket/insertNewBasket',
  async (data, { dispatch, getState }) => {
    let { quantity, RowId, cb, plate, Plate,Image,Category } = data;
    try {







      const create = await insertBasket({
        Quantity: quantity,
        RowId,
        LicensePlate: (plate || Plate),
        Image,Category
      })
      let createData = create.basket


      let price = await fetchPriceAndRoutev2(JSON.stringify([{
        key: createData.RowId,
        rows: [createData.RowId]
      }]))

      let priceData = price.data[0];

      createData.prices = priceData
      if (cb) cb(null)

      return { createData, plate }
    } catch (f) {
      message.error(f?.response?.data?.message || "System error. Please try again.")
      if (cb) cb(true, null)
      return new Error(f?.response?.data?.message || "System error. Please try again.")
    }
  }
)


//Baskete yeni ürün ekler. SİLİNECEK








export const basket = createSlice({
  name: 'account',
  initialState,
  reducers: {
    toggle: (state, action) => {
      state.visible = action.payload
    },
    setBasketList: (state, action) => {
      state.basketList = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setLoadingPrice: (state, action) => {
      state.loadingPrice = action.payload
    },
    addProduct: (state, action) => {
      state.basketList = [...state.basketList, state.basketList[0]]
    },
    setReload: (state, action) => {
      state.reload = state.reload + 1
    },

    setTotalPrices: (state, action) => {
      state.totalPrices = action.payload
    },
    setLoadingAllPriceList: (state, action) => {
      state.loadingAllPriceList = action.payload
    },
    setLoadingAllBasketList: (state, action) => {
      state.loadingAllBasketList = action.payload
    },
    setDeliveries: (state, action) => {
      state.deliveries = action.payload
    }
  },
  extraReducers: {
    [insertNewBasket.fulfilled]: (state, action) => {
      state.basketList = [...state.basketList, action.payload.createData]
    },
    [insertNewBasket.rejected]: (state, action) => {
      //      let findIndex = state.basketList.findIndex(item => onTrimProductReference(item.ProductReference) == onTrimProductReference(action.meta.arg.ProductReference) && item.BrandId == action.meta.arg.BrandId)
    },
    [insertNewBasket.pending]: (state, action) => {

      let findIndex = state.basketList.findIndex(item => item.RowId && item.BrandId == action.meta.arg.RowId)
      if (findIndex != -1) {
        // state.basketList[findIndex].loading = true;
      }
    },


    [updateStoreBasket.pending]: (state, action) => {

    },

    [updateStoreBasket.rejected]: (state, action) => {
      // state.basketList = state.basketList.map(item => {
      //   if (item.Id == action.meta.arg.basketId) {
      //     item.loading = false;
      //   }
      //   return item
      // })      //message.error(action.payload)
      //let findIndex = state.basketList.findIndex(item => onTrimProductReference(item.ProductReference) == onTrimProductReference(action.meta.arg.ProductReference) && item.BrandId == action.meta.arg.BrandId)
      //state.basketList[findIndex].loading = false;
    },

    [updateStoreBasket.fulfilled]: (state, action) => {





      state.basketList = state.basketList.map(item => {
        if (item.Id == action.meta.arg.basketId) {
          item.Quantity = action.meta.arg.Quantity
        }
        return item
      })

    
    },




    [deleteBasketById.fulfilled]: (state, action) => {
      let findIndex = state.basketList.findIndex(item => item.Id == action.meta.arg.basketId)
      if (findIndex != -1) {
        state.basketList = [...state.basketList.slice(0, findIndex), ...state.basketList.slice(findIndex + 1)]
      }
    },

    [deleteBasketById.rejected]: (state, action) => {
      console.log("error")
    },

















  }
})



// Action creators are generated for each case reducer function
export const {  toggle, setReload, addProduct, setLoading, setBasketList, setTotalPrices, setLoadingAllPriceList, setLoadingAllBasketList } = basket.actions
export { getBasketList };
export default basket.reducer

