import React from 'react';
import { createRoot } from "react-dom/client";
import { store } from "./store/store";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import Login from './pages/login';

// import 'antd/dist/antd.css';
import 'antd/dist/antd.min.css'

import './styles/main.css';
// import './styles/index.scss';

import AuthContext from './core/authentication/AuthContext';
import { I18nextProvider } from 'react-i18next';
import i18n from '@/core/translations/i18n'
import Index from './pages';
import DashboardLayout from '@/layouts/Dashboard/DashboardLayout';

// import AccountPage from '@/pages/account';
import InvoicesPage from '@/pages/account/invoices';
import AccountLayout from '@/layouts/AccountLayout/AccountLayout';
import ProfilePage from '@/pages/account/profile';
import ArticleHistory from './pages/account/articleHistory';
import AccountSettings from './pages/account/settings';
import UnpaidInvoices from './pages/account/unpaidInvoices';
import PaidedInvoices from './pages/account/paidedInvoices';
import PackingSlip from './pages/account/packingSlip';
import Catalog from './pages/catalog';
import Bootstrap from './core/bootstrap/Bootstrap';
import Logout from './pages/logout';
import History from './pages/history';
import TecdocLayout from './layouts/TecdocLayout/TecdocLayout';
import TecdocMainCategories from './pages/tecdoc/mainCategories';
import TecdocSubCategories from './pages/tecdoc/subcategories';
import AddTecdocCategory from './pages/tecdoc/addTecdocCategory';
import DispatchBrandCategory from './components/tecdocSettings/TecdocMenuCreate/DispatchBrandCategory';
import axios from 'axios';
import UniversalParts from './pages/universalParts';
import VehicleSearch from './pages/vehicleSearch';

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthContext>
        <DashboardLayout>
          <Index />
        </DashboardLayout>
      </AuthContext>
    ),
  },
  {
    path: "login",
    element: <Login />
  },
  {
    path: "logout",
    element: <Logout />
  },

  {
    path: "catalog",
    element: <AuthContext>
      <DashboardLayout>
        <Catalog />
      </DashboardLayout>
    </AuthContext>
  },
  {
    path: "vehicle-search",
    element: <AuthContext>
      <DashboardLayout>
        <VehicleSearch />
      </DashboardLayout>
    </AuthContext>
  },
  {
    path: "account/invoices",
    element: <AuthContext>
      <DashboardLayout>
        <AccountLayout>
          <InvoicesPage />
        </AccountLayout>
      </DashboardLayout>
    </AuthContext>
  },
  {
    path: "account/profile",
    element: <AuthContext>
      <DashboardLayout>
        <AccountLayout>
          <ProfilePage />
        </AccountLayout>
      </DashboardLayout>
    </AuthContext>
  },
  {
    path: "account/article-history",
    element: <AuthContext>
      <DashboardLayout>
        <AccountLayout>
          <ArticleHistory />
        </AccountLayout>
      </DashboardLayout>
    </AuthContext>
  },
  {
    path: "history",
    element: <AuthContext>
      <DashboardLayout>
        <History />
      </DashboardLayout>
    </AuthContext>
  },
  {
    path: "account/settings",
    element: <AuthContext>
      <DashboardLayout>
        <AccountLayout>
          <AccountSettings />
        </AccountLayout>
      </DashboardLayout>
    </AuthContext>
  },
  {
    path: "account/unpaid-invoices",
    element: <AuthContext>
      <DashboardLayout>
        <AccountLayout>
          <UnpaidInvoices />
        </AccountLayout>
      </DashboardLayout>
    </AuthContext>
  },
  {
    path: "account/paided-invoices",
    element: <AuthContext>
      <DashboardLayout>
        <AccountLayout>
          <PaidedInvoices />
        </AccountLayout>
      </DashboardLayout>
    </AuthContext>
  },
  {
    path: "universal-parts",
    element: <AuthContext>
      <DashboardLayout>
        <UniversalParts />
      </DashboardLayout>
    </AuthContext>
  },
  {
    path: "account/packing-slips",
    element: <AuthContext>
      <DashboardLayout>
        <AccountLayout>
          <PackingSlip />
        </AccountLayout>
      </DashboardLayout>
    </AuthContext>
  },
  {
    path: "tecdoc/menu-create",
    element: <AuthContext>
      <DashboardLayout>
        <TecdocLayout>
          <TecdocMainCategories />
        </TecdocLayout>
      </DashboardLayout>
    </AuthContext>
  },
  {
    path: "tecdoc/menu-create/categories/:categoryId",
    element: <AuthContext>
      <DashboardLayout>
        <TecdocLayout><TecdocSubCategories /></TecdocLayout>
      </DashboardLayout>
    </AuthContext>
  },
  {
    path: "tecdoc/menu-create/categories/:categoryId/subcategories/:subcategoryId",
    element: <AuthContext>
      <DashboardLayout>
        <TecdocLayout>
          <AddTecdocCategory />
        </TecdocLayout>
      </DashboardLayout>
    </AuthContext>
  },
  {
    path: "tecdoc/menu-create/brands/subcategory/:subcategoryId/:genericArticleId/",
    element: <AuthContext>
      <DashboardLayout>
        <TecdocLayout>
          <DispatchBrandCategory />
        </TecdocLayout>
      </DashboardLayout>
    </AuthContext>
  }
]);

try {
  let PS_TOKEN = null
  if (process.env.NODE_ENV === 'development') {

  } else {
    PS_TOKEN = document.getElementById('PS_TOKEN').value
  }

  window.REST_API_TOKEN = PS_TOKEN

  if (process.env.NODE_ENV === 'development') {
    const translations = document.createElement("div"); // display none
    translations.style.display = "none";
    translations.id = "translationsdata";
    const sitesettings = document.createElement('div');
    sitesettings.style.display = "none";
    sitesettings.id = "sitesettings";
    const languagelist = document.createElement('div');
    languagelist.style.display = "none";
    languagelist.id = "languagelist";

    axios.get('http://localhost:3000/developer/vandal.parts-soft.eu').then((res) => {
      sitesettings.innerHTML = JSON.stringify(res.data.siteSettings)
      languagelist.innerHTML = JSON.stringify(res.data.languagelist)
      translations.innerHTML = JSON.stringify(res.data.translations)
      console.log(res.data.siteSettings)
      window.REST_API_TOKEN = res.data.psToken
      document.body.appendChild(sitesettings);
      document.body.appendChild(languagelist);
      document.body.appendChild(translations);

      let pwaDOM = createRoot(document.getElementById("root")).render(
        <I18nextProvider i18n={i18n}>
          <Provider store={store}>
            <Bootstrap><RouterProvider router={router} /></Bootstrap>
          </Provider>
        </I18nextProvider>
      );
    })

    //append body
  } else {
    let pwaDOM = createRoot(document.getElementById("root")).render(
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <Bootstrap><RouterProvider router={router} /></Bootstrap>
        </Provider>
      </I18nextProvider>
    );
  }
} catch (e) {
  console.log(e);
}



