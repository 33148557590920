import React, { useEffect, useState } from "react";
import { Button, Card, Col, DatePicker, Form, Input, message, Modal, Row, Table } from "antd";
import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import axios from "axios";
import transactions from "@/core/transactions/transactions";
import ShowDocument from "@/components/invoices/ShowDocument";
import { useTranslation } from "react-i18next";
import NumberRound from "number-zero-round";
import { useSelector } from "react-redux";

const ArticleHistoryTable = () => {
    const [selectedDocumentNumber, setSelectedDocumentNumber] = useState(null);
    const [showDocumentModal, setShowDocumentModal] = useState(false);
    const [type, setType] = useState(null);
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const columns = [
        {
            title: t("transaction-type"),
            dataIndex: "TransactionTypeId",
            key: "TransactionTypeId",
            sorter: (a, b) => a.TransactionTypeId - b.TransactionTypeId,
            render: (a) => {
                if (a == 1) return t("invoice");
                else if (a == 2) return t("packing-slip");
                else if (a == 3) return t("offer");
                else if (a == 4) return t("work-order");
                else return "Unknown";
            },
        },
        {
            title: t("document-number"),
            dataIndex: "DocumentNumber",
            key: "DocumentNumber",
            sorter: (a, b) => a.DocumentNumber - b.DocumentNumber,
        },
        {
            title: t("invoice-date"),
            dataIndex: "DocumentDate",
            key: "DocumentDate",
            sorter: (a, b) => moment(a.DocumentDate).unix() - moment(b.DocumentDate).unix(),
        },
        {
            title: t("product-reference"),
            dataIndex: "ProductReference",
            key: "ProductReference",
            sorter: (a, b) => a.ProductReference.localeCompare(b.ProductReference),
        },
        {
            title: t("plate"),
            dataIndex: "LicensePlate",
            key: "LicensePlate",
            sorter: (a, b) => a.LicensePlate.localeCompare(b.LicensePlate),
        },
        {
            title: t("quantity"),
            dataIndex: "Quantity",
            key: "Quantity",
            sorter: (a, b) => a.Quantity - b.Quantity,
            render: (a) => NumberRound(a),
        },
        {
            title: t("gross-price"),
            dataIndex: "GrossPrice",
            key: "GrossPrice",
            sorter: (a, b) => a.GrossPrice - b.GrossPrice,
            render: (a) => "€" + NumberRound(a),
        },
        {
            title: t("net-price"),
            dataIndex: "NetPrice",
            key: "NetPrice",
            sorter: (a, b) => a.NetPrice - b.NetPrice,
            render: (a) => "€" + NumberRound(a),
        },
        {
            title: "",
            dataIndex: "",
            key: "download",
            render: (_, record) => {
                return (
                    <div
                        onClick={() => {
                            setSelectedDocumentNumber(record.DocumentNumber);
                            setShowDocumentModal(true);
                            setType(record.TransactionTypeId);
                        }}
                    >
                        <DownloadOutlined style={{ fontSize: "19px", color: "var(--primaryColor)", cursor: "pointer" }} />
                    </div>
                );
            },
        },
    ];

    const search = () => {
        const { term, startDate, endDate } = form.getFieldsValue();

        const start = startDate ? moment(startDate).format("YYYY-MM-DD") : null;
        const end = endDate ? moment(endDate).format("YYYY-MM-DD") : null;

        setLoading(true);
        transactions
            .searchArticleHistory(term, start, end)
            .then((response) => {
                setData(response.data.data);
            })
            .catch(() => {
                message.error("Invoice info not fetching!");
            })
            .finally(() => setLoading(false));
    };

    const session = useSelector((state) => state?.session);

    useEffect(() => {
        search();
    }, [session?.relationId]);

    const defaultStartDate = moment().subtract(1, "months");
    const defaultEndDate = moment();

    return (
        <div>
            <Modal
                bodyStyle={{ height: "1000px", padding: 0 }}
                open={showDocumentModal}
                footer={null}
                width="100%"
                onCancel={() => setShowDocumentModal(false)}
            >
                <ShowDocument documentNumber={selectedDocumentNumber} type={type} />
            </Modal>

            <Form
                layout="vertical"
                form={form}
                onFinish={search}
                initialValues={{
                    startDate: defaultStartDate,
                    endDate: defaultEndDate,
                }}
            >
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item name="startDate" label={t("start-date")}>
                            <DatePicker format="YYYY-MM-DD" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="endDate" label={t("end-date")}>
                            <DatePicker format="YYYY-MM-DD" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="term" label={t("reference")}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="search" label={t("search")}>
                            <Button size="large" type="primary" htmlType="submit">
                                {t("search")}
                            </Button>
                        </Form.Item>

                    </Col>
                </Row>

            </Form>
            <Table
                uid={"DocumentNumber"}
                loading={loading}
                size="small"
                dataSource={data}
                className="table-2"
                columns={columns}
            />
        </div>
    );
};

export default ArticleHistoryTable;
