import UniversalProductCard from '@/components/universalParts/UniversalProductCard';
import useGetProductsBySearchTecdoc from '@/hooks/useGetProductsBySearchTecdoc';
import { Alert, Col, Input, Radio, Row, Typography } from 'antd';
import React, { useEffect } from 'react';
import { Pagination, Select } from 'antd';
import UniversalProductSkeletonCard from '@/components/universalParts/UniversalProductSkeletonCard';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'; // Use these instead of useHistory
import UniversalPartsTable from '@/components/universalParts/UniversalPartsTable';

const UniversalParts = () => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(12);
  const [viewType, setViewType] = React.useState('2');
  const [value, setValue] = React.useState(1);

  const useSearch = useGetProductsBySearchTecdoc();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  // Handle search with URL query parameters
  const updateUrl = (term, page, size) => {
    const queryParams = new URLSearchParams();
    if (term) queryParams.set('search', term);
    queryParams.set('page', page);
    queryParams.set('size', size);
    navigate({ search: queryParams.toString() });
  };

  const onOk = () => {
    updateUrl(searchTerm, currentPage, pageSize);
    useSearch.search(searchTerm, currentPage, pageSize);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    updateUrl(searchTerm, page, pageSize);
    useSearch.search(searchTerm, page, pageSize);
  };

  const onShowSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(1);
    updateUrl(searchTerm, 1, size);
    useSearch.search(searchTerm, 1, size);
  };

  useEffect(() => {
    const search = searchParams.get('search') || '';
    const page = parseInt(searchParams.get('page')) || 1;
    const size = parseInt(searchParams.get('size')) || 12;

    setSearchTerm(search);
    setCurrentPage(page);
    setPageSize(size);

    if (search) {
      useSearch.search(search, page, size);
    }
  }, [searchParams]);


  console.log(useSearch.products);

  return (
    <div>
      <Alert banner type="warning" message={t('beta-message', { version: '1.0.0' })} />

      <Row className="mt-5" gutter={16}>
        <Col span={24}>
          <Typography.Title className="text-center" level={1}>
            {t('universal-parts')}
          </Typography.Title>
        </Col>

        <Col lg={2} >
          <div style={{ display: 'flex', alignItems: 'center', 'height': '100%' }}>
            <Radio.Group value={viewType} onChange={(e) => setViewType(e.target.value)}>
              <Radio value={'1'}>{t('list-view')}</Radio>
              <Radio value={'2'}>{t('card-view')}</Radio>
            </Radio.Group>
          </div>
        </Col>


        <Col span={4}>
          <Select
            size="large"
            className="w-full"
            value={pageSize}
            onChange={onShowSizeChange}
          >
            <Select.Option value={12}>{t('page-size-12')}</Select.Option>
            <Select.Option value={24}>{t('page-size-24')}</Select.Option>
            <Select.Option value={50}>{t('page-size-50')}</Select.Option>
            <Select.Option value={100}>{t('page-size-100')}</Select.Option>
          </Select>
        </Col>

        <Col span={18}>
          <Input.Search
            loading={useSearch.loading}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                onOk();
              }
            }}
            enterButton={t('search')}
            size="large"
            placeholder={t('search-placeholder')}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Col>



      </Row>



      {viewType == '1' ?

        <div>

          <div className="mt-[20px]">
            <Pagination
              disabled={useSearch.loading}
              showSizeChanger={false}
              // style={{ display: 'flex', justifyContent: 'space-between' }}
              current={currentPage}
              pageSize={pageSize}
              total={useSearch.total}
              onChange={handlePageChange}
              showTotal={() => t('total-items', { total: useSearch.total })}
            />
          </div>
          <div className="mt-50">

            <Row gutter={16}>
              {useSearch.loading
                ? Array(12)
                  .fill()
                  .map((_, i) => (
                    <Col className="mt-20" lg={8} key={i}>
                      <UniversalProductSkeletonCard />
                    </Col>
                  ))
                : useSearch.products.map((product) => (
                  <Col className="mt-20" lg={8} key={product.id}>
                    <UniversalProductCard deliveries={useSearch.deliveries} product={product} />
                  </Col>
                ))}
            </Row>
          </div>
        </div>

        : <div>

          <UniversalPartsTable pageSize={pageSize} deliveries={useSearch.deliveries} dataSource={useSearch.products} />

          <div className="mt-[20px]">
            <Pagination
              disabled={useSearch.loading}
              showSizeChanger={false}
              // style={{ display: 'flex', justifyContent: 'space-between' }}
              current={currentPage}
              pageSize={pageSize}
              total={useSearch.total}
              onChange={handlePageChange}
              showTotal={() => t('total-items', { total: useSearch.total })}
            />
          </div>

        </div>}

    </div>
  );
};

export default UniversalParts;
