import React from 'react'
import GarageIcon from "../../icons/GarageIcon";
import SellerIcon from "../../icons/SellerIcon";
import BoxIcon from "../../icons/BoxIcon";
import useLogisticTimeToText from '@/hooks/useLogisticTimeToText';
import QuestionIcon from '@/components/icons/QuestionIcon';
import { useTranslation } from 'react-i18next';
import { Dropdown, Popover } from 'antd';
import { useSelector } from 'react-redux';

const BuyAreav2Logistic = ({ warehouseName,isExist, StockQuantity, otherWarehouseStocks, saleStatus, logisticTime, logisticTimeToRegion, supplierId }) => {

  const isAdmin = (useSelector((e) => e?.session?.account?.Role) == 1) ? true : false

  const logisticTimeText = useLogisticTimeToText(logisticTime)
  const logisticTimeToRegionText = useLogisticTimeToText(logisticTimeToRegion)

  const { t } = useTranslation()
  if (isExist) {
    return (
      <React.Fragment>
        <div className="flex items-center justify-center p-[5px] relative self-stretch w-full flex-[0_0_auto] overflow-hidden">
          <div className="inline-flex items-center p-[5px] relative flex-[0_0_auto]">
            <div className="inline-flex flex-col items-center justify-center p-[5px] relative flex-[0_0_auto]">

              {/* {isAdmin &&  fastestAvability?.supplierId && <div className="relative w-fit [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[12px] text-right tracking-[0] leading-[normal]">
          <div className='flex'><b>{fastestAvability?.supplierId}</b></div>
        </div> } */}
              <Dropdown dropdownRender={() => {
                return (<div className='bg-[white] p-[10px] rounded-[6px]'>
                  <div className='flex flex-row items-center gap-[10px]'>

                    {!isAdmin ? (<>
                      <p>{t('supplier')}</p>
                      <p>{supplierId}</p>
                    </>
                  ) 
                  : (<>
                  <p>{t('warehouse')}</p>
                  <p>{warehouseName}</p>
                  </>)
                }
                  
                    
                  
                  </div>
                </div>)
              }}>
                <a>
                  <GarageIcon />
                </a>
              </Dropdown>

            </div>
            {(otherWarehouseStocks && (StockQuantity)) && <div className="relative w-fit [font-family:'Poppins-Regular',Helvetica] font-normal text-[#6f6f6f] text-[13px] tracking-[0] leading-[normal]">
              {parseInt(otherWarehouseStocks)}
            </div>}
          </div>
          <div className="inline-flex items-center p-[5px] relative flex-[0_0_auto]">
            <div className="inline-flex flex-col items-center justify-center p-[5px] relative flex-[0_0_auto]">
              <SellerIcon />
            </div>

            {(!isNaN((StockQuantity))) && <div className="relative w-fit [font-family:'Poppins-Regular',Helvetica] font-normal text-[#6f6f6f] text-[13px] tracking-[0] leading-[normal]">
              {(StockQuantity)}
            </div>}

          </div>
          {isExist ? <div className="inline-flex items-center p-[5px] relative flex-[0_0_auto]">
            <div className="inline-flex flex-col items-center justify-center p-[5px] relative flex-[0_0_auto]">
              <BoxIcon />
            </div>
            <div style={{
              cursor: 'pointer'
            }} className="relative w-fit [font-family:'Poppins-Regular',Helvetica] font-normal text-[#0da487] text-[13px] tracking-[0] leading-[normal]">
              <Popover content={<div>
                {logisticTimeToRegionText}
              </div>}>
                <a>{t('direct')}</a>
              </Popover>
            </div>
          </div> :

            0 ? <div className="inline-flex items-center p-[5px] relative flex-[0_0_auto]">
              <div className="inline-flex flex-col items-center justify-center p-[5px] relative flex-[0_0_auto]">
                <GarageIcon />
              </div>
              <div className="relative w-fit [font-family:'Poppins-Regular',Helvetica] font-normal text-[#1C9AE1] text-[13px] tracking-[0] leading-[normal]">
                Route
              </div>
            </div> : null

          }

        </div>
      </React.Fragment>
    )

  } else if (saleStatus) {




    return (
      <React.Fragment>
        <div className="flex items-center justify-center p-[5px] relative self-stretch w-full flex-[0_0_auto] overflow-hidden">
          <div className="inline-flex items-center p-[5px] relative flex-[0_0_auto]">
            <div className="inline-flex flex-col items-center justify-center p-[5px] relative flex-[0_0_auto]">
              {logisticTimeToRegionText ? <div className='flex flex-row items-center justify-center gap-[5px] p-[5px]'>

                <Dropdown dropdownRender={() => {
                  return (<div className='bg-[white] p-[10px] rounded-[6px]'>
                    <div className='flex flex-row items-center gap-[10px]'>
                    
                    {!isAdmin ? (<>
                      <p>{t('supplier')}</p>
                      <p>{supplierId}</p>
                    </>
                  ) 
                  : (<>
                  <p>{t('warehouse')}</p>
                  <p>{warehouseName}</p>
                  </>)
                }




                    </div>
                  </div>)
                }}>
                  <a>
                    <GarageIcon />
                  </a>
                </Dropdown>

                <div className="relative w-fit [font-family:'Poppins-Regular',Helvetica] font-normal text-[#6f6f6f] text-[13px] tracking-[0] leading-[normal]">{parseInt(StockQuantity)}</div>

              </div> : "........."}

            </div>
            <div>
            </div>
            <div className="relative w-fit [font-family:'Poppins-Regular',Helvetica] font-normal text-[#1C9AE1] text-[13px] tracking-[0] leading-[normal] flex flex-row gap-[5px]">
              <GarageIcon color="#1C9AE1" /> {logisticTimeToRegionText || t('unkown-logistic-time')}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }



}

export default BuyAreav2Logistic