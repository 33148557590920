import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Avatar, Divider, message, Spin, List, Tag } from "antd";
import { useDispatch } from "react-redux";
import { deleteHistoryList, getHistoryList } from "@/store/history";
import useAddTab from "@/hooks/assistant/useAddTab";
import getCatalogName from "@/helpers/getCatalogName";




const ParseYear = (str) => {
  if (!str) return ""
  try {
    if (str.search('-') != -1) {
      return str.replaceAll('-', '').slice(0, 4)
    }
    else {
      return str.slice(0, 4)
    }
  } catch (e) {
    return str;
  }
}


const ParseMount = str => {
  if (!str) return ""

  try {
    if (str.search('-') != -1) {
      return str.replaceAll('-', '').slice(4, 6)
    }
    else {
      return str.slice(4, 6)
    }
  } catch (e) {
    return str;
  }
}




const ResultHistory = () => {
  const [historyLimit, setHistoryLimit] = useState(3);
  const historyList = useSelector((e) => e.history.historyList);
  const loading = useSelector((e) => e.history.loadingHistory);
  const d = useDispatch();
  const addTab = useAddTab();
  const getHistory = (limit) => {
    d(getHistoryList());
  };

  const catalogName = getCatalogName()

  const { t } = useTranslation()

  useEffect(() => {
    getHistory();
  }, []);

  const onClickHandler = (e) => {
    let props = {
      title: e.BrandName + " " + e.VehicleLineName + " / " + e.LicensePlate,
      type: "Assistant",
      section: "vehicleMenu",
      make: 0,
      makeName: e.BrandName,
      vehicleLineId: 0,
      vehicleLineName: e.VehicleLineName,
      vehicleSeriesName: e.VehicleReferenceName,
      vehicleMenuName: "",
      vehicleSeriesId: e.VehicleReferenceId,
      source: "history",
      plate: e.LicensePlate

    }
    addTab(props, 'redirect')
  };

  const loadMoreHandler = () => {
    let v = historyLimit;
    setHistoryLimit(v + 3);
    //  getHistory(v + 3);
  };

  const clearHandler = () => {
    d(deleteHistoryList());
  };


  return (
    <>
      <Spin spinning={loading}>
        <div className="flex justify-between items-center">
          <b>{t("history")}</b>
          <p onClick={clearHandler} className="cursor-pointer" style={{ color: "#2b94c8" }}>
            {t('clear')}
          </p>
        </div>
        <Divider />
        <List>
          {historyList.map((item, key) => {
            let stop = false;

            if (key > historyLimit) stop = true;
            else stop = false;

            if (stop) return null;
            else
              return item ? (
                <>
                  <List.Item className="only-show-desktop" key={key} onClick={() => onClickHandler(item)}>
                    <List.Item.Meta
                      avatar={<Avatar style={{ width: "50px", height: "auto" }}

                        src={
                          catalogName === 'TECDOC'
                            ? 'https://webshop.parts-soft.eu/tecdoc/' + item?.VehicleReferenceId + '.jpg'
                            : item.Image
                        }
                        onError={(e) => {
                          e.target.src = ''; // Varsayılan bir resim
                        }}

                      />}
                      title={
                        <div className="flex justify-between">


                          <div style={{ width: '45%' }}>
                            <a style={{ color: "black !important" }} href={"#"}>{`${item.BrandName} ${item.VehicleLineName} / ${item.BodyType}`}</a>
                          </div>

                          <div style={{ width: '45%' }}>
                            {ParseYear(item?.BuildStart) + "-" + ParseMount(item?.BuildStart) + ' / ' + ParseYear(item?.BuildEnd) + "-" + ParseMount(item?.BuildEnd)}
                          </div>
                          <div style={{ width: '10%', display: 'flex', justifyContent: 'flex-end' }}>
                            {item.LicensePlate && item.LicensePlate != "" ? (
                              <div className="">
                                <Tag style={{ margin: "0", borderRadius: "0 !important" }} color={"#006ee0"}>
                                  {"NL"}
                                </Tag>
                                <Tag id="tag-black-tag" color={"#ffc701"}>
                                  {item.LicensePlate.toUpperCase()}
                                </Tag>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      }
                    />
                  </List.Item>

                  <List.Item className="only-show-mobil" key={key} onClick={() => onClickHandler(item)}>
                    <List.Item.Meta
                      title={

                        <>
                          <div className="flex justify-between">
                            <Avatar style={{ width: "50px", height: "auto" }} src={item?.Image} />
                            {item.LicensePlate && item.LicensePlate != "" ? (
                              <div className="">
                                <Tag style={{ margin: "0", borderRadius: "0 !important" }} color={"#006ee0"}>
                                  {"NL"}
                                </Tag>
                                <Tag id="tag-black-tag" color={"#ffc701"}>
                                  {item.LicensePlate.toUpperCase()}
                                </Tag>
                              </div>
                            ) : null}
                          </div>
                          <div className="flex justify-between mt-3">
                            <a style={{ color: "black !important" }} href={"#"}>{`${item.BrandName} ${item.VehicleLineName} / ${item.BodyType}`}</a>
                          </div>
                        </>
                      }
                    />
                  </List.Item>


                </>
              ) : null;
          })}
        </List>
        {historyLimit >= historyList.length ? null : (
          <div onClick={loadMoreHandler} style={{ color: "#2b94c8" }} className="flex justify-center cursor-pointer">
            {t('load-more')}
          </div>
        )}
      </Spin>
    </>
  );
};
export default ResultHistory;
