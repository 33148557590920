import React from "react";
import { Card, Tag, Typography, Image, Row, Descriptions } from "antd";
import getCatalogName from "@/helpers/getCatalogName";
const HistoryItem = ({ item, onClick }) => {
  const [brandLogo, setBrandLogo] = React.useState("");

  React.useEffect(() => {
    if (item && item.BrandName) {
      setBrandLogo("https://material.parts-soft.net/logo.php?brandName=" + item.BrandName.toLowerCase());
    }
  }, [item]);

  const catalogName = getCatalogName();


  return (
    <>
      {item ? <div className="mt-3">
        <div className="history">
          <div className="history__header">
            <div className="history__header__container">
              <div className="history__header__container__tag-1"></div>
            </div>
          </div>
          <div className="history__container cursor-pointer" onClick={() => onClick(item)}>
            <div className="history__image__container">
              <Image className="history__image__container__img"

                src={
                  catalogName === 'TECDOC'
                    ? 'https://webshop.parts-soft.eu/tecdoc/' + item?.VehicleReferenceId + '.jpg'
                    : item.Image
                }
                onError={(e) => {
                  e.target.src = ''; // Varsayılan bir resim
                }}




              />
              {item.LicensePlate && typeof item.LicensePlate != '' ? <div className="mt-3">
                <Tag style={{ margin: "0", borderRadius: "0 !important" }} color={"#006ee0"}>
                  {"NL"}
                </Tag>
                <Tag id="tag-black-tag" color={"#ffc701"}>
                  {item?.LicensePlate?.toUpperCase()}
                </Tag>
              </div> : null}
            </div>

            <div className="history__container__description">
              <p className="font-medium text-[14px]"> {item.BrandName + " - " + item.VehicleLineName}</p>
              <p>{item.BodyType + " " + item.EngineType + " " + item.EngineContents}</p>
            </div>

            <div>
              <Image className="history__image__container__img mb-5" height={70} src={item.Logo} />
            </div>
          </div>
        </div>
      </div> : null}
    </>
  );
};

export default HistoryItem;
